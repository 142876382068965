.menuBar {
    position: static;
    padding: 0 0 100px;
}
.menuBar>p {
    font-family: "Nunito", sans-serif;
    font-size: 30px;
    font-style: normal;
    text-align: center;
    color: #848484;
    text-transform: capitalize;
    font-weight: 300;
    margin: 40px 0 0;
}

.menuBar:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;
    background: #dcdcdc;
    left: 0;
}

.sectionContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    padding-bottom: 70px;

}
.leftPart{
    text-align: right;
    padding: 0 40px;
    color: #fff;
    flex-basis: 33%;
}
@media (max-width: 640px) {
    .leftPart {
        display: none;
    }
}

.leftPart h2, .leftPart h3 {
    padding: 0;
    margin: 0;
    color: #5b5b5b;
    text-align: right;
}

.leftPart h3 {
    font-size: 14px;
    text-transform: uppercase;
}

.awards {
    column-count: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    padding: 0;
}

.awards li {
    padding: 0 20px;
    margin: 5px 0;
    position: relative;
}

ul.awards li a {
    padding-left: 30px;
    font-size: 14px;
}

.awards li:not(.empty):before {
    content: '';
    position: absolute;
    display: block;
    width: 26px;
    height: 16px;
    background: url('../../../assets/images/icon_gallery.svg') center center no-repeat;
    top: 3px;
}