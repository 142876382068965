.internal {
    background: #f7f7f7;
    border-top: 1px solid #d5d5d5;
    padding-bottom: 150px;
}

.internal h1 {
    margin: 125px 0 75px;
    color: #c12747;
}

.internal h1 strong {
    display: block;
    color: #c12747;
    padding-top: 10px;
}

.internal h2 {
    text-align: left;
    margin: 60px 0 30px;
    color: #000;
    font-size: 28px;
}

.internal .extended{
    color: #D23C3B;
}

.internal.terms ul li {
    list-style-type: circle;
    display: list-item;
}

.internal p, .internal ol li, .internal.terms ul li {
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 25px;
    color: #1E1E1E;
    font-style: normal;
    margin-bottom: 20px;
    padding: 0;
}

.buttonLink{
    color: #F7F7F7;
    font-family: 'Conv_Intro';
    font-size: 23px;
    letter-spacing: 0;
    line-height: 28px;
    text-align: center;
    display: inline-block;
    background: #D23C3B;
    padding: 20px 70px;
    margin-top: 120px;
}

.buttonLink:hover {
    background: rgba(188, 24, 24, .8);
    color: #fff;
    text-decoration: none;
}
.buttonLink:visited{
    text-decoration: none;
    color: #fff;
}

.important{
    font-size: 22px;
}