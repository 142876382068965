.footerWrap{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

footer h6{
    font-family: "Conv_Intro", sans-serif;
    color: #fff;
    font-size: 14px;
    margin: 15px 0;
    text-transform: uppercase;
}

footer p{
    font-size: 14px;
    color: #6b6b6b;
    max-width: 350px;
}

footer .social{
    width: 90px;
    margin: 0 auto;
    text-align: center;
}

footer .social .facebook {
    width: 10px;
    height: 20px;
    background: url('../../assets/images/facebook.png') center center no-repeat;
    display: inline-block;
    vertical-align: middle;
    background-size: contain;
    margin: 0 10px;
}

footer .social h4{
    font-family: "Conv_Intro", sans-serif;
    color: #fff;
    font-size: 14px;
}

footer .copy {
    color: #797979;
    font-size: 14px;
    margin-top: -20px;
}
footer .darina{
    display: inline-block;
    vertical-align: baseline;
    width: 19px;
    height: 20px;
    background: url('../../assets/images/darina.png') center center no-repeat;
    background-size: contain;
    margin-left: 5px;
}

@media all and (max-width: 980px) {

    .footerWrap{
        flex-direction: column;
        text-align: center;
    }
    footer .float-right,
    footer .float-left {
        float: none;
        text-align: center;
        margin: 10px 0;
    }

    footer .float-left p {
        margin: 10px auto;
    }

    footer .social {
        padding: 10px 0;
    }
    footer .copy{
        margin-top: 20px;
    }
}