/*----------------COMMON-----------------*/
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;700;900&display=swap');

html body {
    font-family: 'Nunito', sans-serif;
    min-height: 100%;
}

html {
    height: 100%;
}

body {
    min-height: 100%;
}


@font-face {
    font-family: 'Conv_Intro';
    src: url('../fonts/Intro.eot');
    src: local('â˜º'), url('../fonts/Intro.woff') format('woff'), url('../fonts/Intro.ttf') format('truetype'), url('../fonts/Intro.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Intro_Book';
    src: url('../fonts/Intro-Book.eot');
    src: local('â˜º'), url('../fonts/Intro-Book.woff') format('woff'), url('../fonts/Intro-Book.ttf') format('truetype'), url('../fonts/Intro-Book.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

body h1,
body h2,
body h3,
body h4 {
    font-family: 'Conv_Intro';
    text-transform: uppercase;
    text-align: center;
}

#ReactBackgroundSlider > figure{
    position: absolute!important;
}

/*----------------LANGUAGE DROPDOWN---------------*/

.Dropdown-root {
    position: relative;
}
a{
    text-decoration: none;
}
.Dropdown-control {
    position: relative;
    overflow: hidden;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 2px;
    box-sizing: border-box;
    color: #333;
    cursor: default;
    outline: none;
    padding: 8px 52px 8px 10px;
    transition: all 200ms ease;
}

.Dropdown-control:hover {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}

.Dropdown-arrow {
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: ' ';
    display: block;
    height: 0;
    margin-top: -ceil(2.5);
    position: absolute;
    right: 10px;
    top: 14px;
    width: 0
}

.is-open .Dropdown-arrow {
    border-color: transparent transparent #999;
    border-width: 0 5px 5px;
}

.Dropdown-menu {
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    margin-top: -1px;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1000;
    -webkit-overflow-scrolling: touch;
}

.Dropdown-menu .Dropdown-group > .Dropdown-title{
    padding: 8px 10px;
    color: rgba(51, 51, 51, 1);
    font-weight: bold;
    text-transform: capitalize;
}

.Dropdown-option {
    box-sizing: border-box;
    color: rgba(51, 51, 51, 0.8);
    cursor: pointer;
    display: block;
    padding: 8px 10px;
}

.Dropdown-option:last-child {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
}

.Dropdown-option:hover {
    background-color: #f2f9fc;
    color: #333;
}

.Dropdown-option.is-selected {
    background-color: #f2f9fc;
    color: #333;
}

.Dropdown-noresults {
    box-sizing: border-box;
    color: #ccc;
    cursor: default;
    display: block;
    padding: 8px 10px;
}
div.Dropdown-control, div.Dropdown-control:hover {
    background-color: transparent;
    box-shadow: none;
    border: none;
    padding-right: 30px;
}

.Dropdown-control {
    white-space: nowrap;
}

.Dropdown-option {
    text-transform: uppercase;
    font-weight: bold;
}

div.Dropdown-control:before {
    content: '';
    display: inline-block;
    width: 13px;
    height: 13px;
    margin-right: 7px;
    background: transparent url("../assets/images/language.svg") center center no-repeat;
    background-size: contain;
}

span.Dropdown-arrow, .is-open span.Dropdown-arrow {
    border-color: #000000 transparent;
}

.Dropdown-placeholder {
    display: inline-block;
}

.Dropdown-root:hover, .Dropdown-placeholder:hover, .Dropdown-control:hover {
    cursor: pointer;
}

.Dropdown-placeholder {
    color: #BB1817;
    text-transform: uppercase;
    font-family: "Conv_Intro", Sans-Serif;
}


.lang li.active {
    color: #bc1818;
}

@media (min-width: 768px) {
    .container {
        max-width: 750px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 970px;
        width: 970px;
    }
}

.lang li:last-of-type {
    border-top: 1px solid #cdcdcd;
}

.Dropdown-root {
    display: flex;
    margin-right: 15px;
}

@media (max-width: 980px) {
    .Dropdown-root {
        order: 3;
    }
    div.Dropdown-control:before {
        display: none;
    }

}

.carousel .slide {
    background: transparent !important;
    padding: 0 10px;
}


.carousel .slide img {
    max-height: 630px;
    width: auto !important;
    max-width: 100%;
}

#dynamiccontentcarousel img {
    height: 520px;
    margin: 0 auto;
    cursor: pointer;
}

.popup.author .carousel-control.right {
    right: -70px;
}

.popup.author .carousel-control.left {
    left: -70px;
}

.popup.author.black h4 {
    color: #a0a0a0;
}

.m-brick {
    padding: 10px;
}

.carousel-indicators {
    display: none;
}

.carousel.carousel-slider {
    overflow: visible !important;
}
.carousel .slide img {
    max-height: 630px;
    width: auto !important;
    max-width: 100%;
}

@media all and (max-width: 480px) {
    .carousel .slide img{
        max-height: 320px;
    }
}

.carousel ul.control-dots{
    padding: 10px 0 70px;
}

.arrow-left, .arrow-right {
    width: 38px;
    height: 65px;
    background: url("../assets/images/ar_right_gr.svg") center center no-repeat;
    display: block;
    cursor: pointer;
    position: absolute;
    right: -40px;
    top: calc(50% - 32px);
    z-index: 100;
}

.arrow-left {
    left: -40px;
    background-image: url("../assets/images/ar_left_gr.svg");
}

.arrow-right:hover {
    background-image: url("../assets/images/ar_right_red.svg");
}

.arrow-left:hover {
    background-image: url("../assets/images/ar_left_red.svg")
}

@media (min-width: 992px) {
    .arrow-right{
        right: 20px;
    }
    .arrow-left{
        left: 20px;
    }

}

/*....loader.....*/

.loader,
.loader:before,
.loader:after {
    background: #c0c0c0;
    -webkit-animation: load1 1s infinite ease-in-out;
    animation: load1 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
}

.loader {
    color: #c0c0c0;
    text-indent: -9999em;
    margin: 88px auto;
    position: absolute;
    bottom: 0;
    left: calc(50% - 5px);
    font-size: 11px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

.loader:before,
.loader:after {
    position: absolute;
    top: 0;
    content: '';
}

.loader:before {
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.loader:after {
    left: 1.5em;
}

.popup+.wrap {
    display: none;
}

.popup.author {
    padding: 25px 0 50px;
    text-align: center;
    min-height: 100vh;
}

.author.black {
    background: #000;
}

.my-gallery-class {
    padding: 0;
    margin: 0 auto;
    max-width: 750px;
}

.infinite-scroll-component{
    overflow: hidden!important;
}


.my-gallery-class img{
    cursor: pointer;
}

@media all and (max-width: 800px) {
    .my-gallery-class{
        max-width: 400px;
    }
}

@media all and (max-width: 600px) {
    .my-gallery-class{
        max-width: 250px;
    }


}

@media all and (max-width: 480px) {
    .my-gallery-class img{
        pointer-events: none;
    }
}
